import HttpClient from "../../admin/pagebuilder/components/httpClient";
import stringToNode from "../../admin/pagebuilder/components/htmlParser";

export default class Winning {
    constructor(el) {
        this.node = el;
        this.winnersBar = this.node.querySelector('[data-tg-c]');
        this.handleEvents();
    }

    handleEvents() {
        setInterval(() => {
            this.getWinner();
        }, 7000)
    }

    getWinner() {
        const client = new HttpClient();
        client.post('/ajax/winning/', {
            exclude: this.getExistingIds(),
            locale : pageLocale
        }).then((res) => {
            this.winnersBar.innerHTML = res.items;
        })
    }

    getExistingIds() {
        const ids = [];

        this.node.querySelectorAll('.top-games__item').forEach((el) => {
            ids.push(el.getAttribute('data-gid'));
        });

        return ids;
    }
}